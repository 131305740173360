$(document).foundation();

/* =======================
Ajax Form Submission
========================*/
function ajaxWebformSubmit(options) {
  var settings = $.extend({
        formID : null,
        formWrapper: null,
        successMsg : 'Your message has been sent!',
        errorMsg : 'Sorry, looks like there was a problem. Please try again.'
    },options);

  $.ajax({
      url: $(settings.formID).attr("action") + "&JSON=1",
      data: $(settings.formID).serialize(),
      type: "POST"
    }).done(function(msg) {
      var formResponse = eval(msg);
        if (formResponse.FormProcessV2Response.success) {
          if (settings.successMsg) {
            $(settings.formWrapper).after("<div class=\"json-success\" style=\"display:none;\">" + settings.successMsg + "</div>");
          }
          $(settings.formWrapper).hide();
          $(".json-success").fadeIn(400);
        }
    }).fail(function(msg) {
      alert(settings.errorMsg + msg);
  });
}

/* ==================================================
Active Nav:

Adds the class of 'selected' to the anchor (if any)
that matches the current path.
===================================================*/

const path = location.pathname.substring(1);

if(path) {
  $(`#main-menu a[href$="/${path.split('/')[0]}/"]`).parent().addClass('selected');
} else {
  $(`#main-menu a[href="/"]`).parent().addClass('selected');
}

/* =======================
Background image rotator
========================*/
$(function(){
    var infiniteRotatorItems = $('#rotating-item-wrapper').data('rotator-items');

    var addInfiniteRotatorItems = function() {
      for(var i = infiniteRotatorItems-1; i > 0; i--) {
        $('.rotating-item.img-0').after('<div class="rotating-item img-'+ i +'"></div>');
      }
    };

    addInfiniteRotatorItems();

    var InfiniteRotator = {
        init: function() {
            //initial fade-in time (in milliseconds)
            var initialFadeIn = 1000;

            //interval between items (in milliseconds)
            var itemInterval = 4000;

            //cross-fade time (in milliseconds)
            var fadeTime = 1500;

            //count number of items
            var numberOfItems = $('.rotating-item').length;

            //set current item
            var currentItem = 0;

            //show first item
            // $('.rotating-item').eq(currentItem).fadeIn(initialFadeIn);

            //loop through the items
            var infiniteLoop = setInterval(function() {
                $('.rotating-item').eq(currentItem).fadeOut(fadeTime);

                if (currentItem == numberOfItems - 1) {
                    currentItem = 0;
                } else {
                    currentItem++;
                }
                $('.rotating-item').eq(currentItem).fadeIn(fadeTime);

            }, itemInterval);
        }
    };

    InfiniteRotator.init();

});
